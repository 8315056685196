import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import DatePicker from "@/components/DatePicker";
import useConfirm from "@/components/Modal/Confirm";

import { stateList } from "@/types/enum";
import { disciplineOptions as options, membershipPlans } from "@/types/enum";

import { artistList } from "./../../apis/artist-api";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { ReactComponent as Filter } from "@/images/filter.svg";
import "./admin_artists.less";
import {
  getUserTag,
  userAccess,
  userDelete,
  userEditTag,
  userNote,
  userSuspend,
  userTag,
} from "@/adminApp/apis/user-api";
import queryString from "query-string";
import { getEnv } from "@/libs/cfg";
import { membershipTypes } from "@/types/enum";
import { admin_router_message_to } from "@/adminApp/routers";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

export const ethnic = [
  "American indian / Alaskan native",
  "Arab / Middle Easten",
  "Asian / Asian-American",
  "Black / African-American",
  "Hispanic / Latinx",
  "Native Hawaiian / Pacific Islander",
  "White / Caucasian",
  "Other",
];

export const memberTypes = [
  {
    label: "Fee Paid",
    value: "paid",
  },
  {
    label: "Free Profile",
    value: "free",
  },
  {
    label: "Pending Sponsorship",
    value: "pending",
  },
  {
    label: "Sponsorship Approved",
    value: "approved",
  },
  {
    label: "Sponsorship Active",
    value: "active",
  },
  {
    label: "Sponsorship Expired",
    value: "expired",
  },
  // {
  //   label: "SA Expired Grace Period",
  //   value: "expired",
  // },
];

export const tags = [
  "Arts Organizations",
  "Board of Directors",
  "Funding Organization",
  "DO NOT CONTACT",
  "Potential Donor",
  "Elected Official",
  "Artist Council",
  "Report NOT Received",
  "Former Board of Directors",
  "Former Artist Council",
];

export const roles = [
  {
    label: "Artist",
    value: "artist",
  },
  {
    label: "Donor",
    value: "donor",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const AdminArtists = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { setShow, component } = useConfirm(
    <div className="flex column start align-start">
      <p>You are suspending this account By clicking ‘Suspend’, you will:</p>
      <ul>
        <li>Cancel all subscriptions </li>
        <li>Suspend this account </li>
        <li>
          Unpublish all content from this user, including artist profile,
          sponsor artist contract, all active events and campaigns
        </li>
        <li>Disable this user from loging in </li>
        <li>Back up all content in the database.</li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to suspend this account ? ",
      className: "suspend-confirm-modal",
      okText: "suspend",
      onOk: (id?: any) => {
        userSuspend({
          id,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are about to permantly delete this account.
        <br /> By clicking ‘Delete’, you will:
      </p>
      <ul>
        <li>Cancel all subscriptions </li>
        <li>Delete this account </li>
        <li>Delete all content related to this account</li>
        <li>Disable this user from loging in </li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to permanently delete this account ?  ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        userDelete({
          id,
        }).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );

  //handler
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/artist/export-csv?${queryString.stringify({
        ...others,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  // -add tag
  const [tagForm] = Form.useForm();
  const [tagList, setTagList] = useState<any[]>([]);
  const [tagModal, setTagModal] = useState<object | boolean>(false);
  const getTags = () => {
    getUserTag().then((data) => setTagList(data));
  };
  const handleShowTagModal = (row: any) => {
    setTagModal(row);
    if (row !== true) {
      const { id, userTags = "" } = row;
      tagForm.setFieldsValue({
        id,
        tags: userTags || [],
      });
    }
  };
  const handleTagFinish = (values: any) => {
    setLoading(true);
    if (tagModal === true) {
      userTag(values)
        .then((data) => {
          message.success("Add successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getTags();
        })
        .catch(() => setLoading(false));
    } else {
      userEditTag(values)
        .then(() => {
          message.success("Save successfully");
          tagForm.resetFields();
          setTagModal(false);
          setLoading(false);
          getList();
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  // -view note
  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(false);
  const handleViewNote = (row: any) => {
    setNoteModal(true);
    const { userRole, userTags, userNote, ...others } = row;
    noteForm.setFieldsValue({
      ...others,
      note: userNote,
      roles: userRole[0],
      tag: userTags?.join(","),
    });
  };
  const handleNoteFinish = (values: any) => {
    const { userId, note } = values;
    userNote({
      id: userId,
      note,
    }).then((data) => {
      setNoteModal(false);
      getList();
      message.success("successfully");
    });
  };
  const handleAccessToUser = (id: number) => {
    userAccess({ id }).then((data) => {
      window.open(`${getEnv("WEB_HOST")}/auth/${data.token}`, "_blank");
    });
  };

  const handleSuspend = (row: any) => {
    setShow(row.userId);
  };
  const handleDelete = (row: any) => {
    deleteShow(row.userId);
  };

  //handler end

  // -search
  const column: any[] = [
    {
      title: "first name",
      dataIndex: "firstName",
      width: 120,
      fixed: "left",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: 120,
      fixed: "left",
    },
    {
      title: "Company",
      width: 120,
      dataIndex: "companyName",
    },
    {
      title: (
        <>
          MEMBERSHIP STATUS
          <Tooltip
            overlay={<div style={{padding:8}}>Note: Expired accounts and crowdfunding campaigns may remain Active for 1-2 days after a failed payment attempt.</div>}
          >
            <QuestionCircleOutlined size={12} style={{ padding: 6, minWidth: 20 }}/>
          </Tooltip>
        </>
      ),
      width: 200,
      dataIndex: "membershipType",
      render: (text: string, row: any) => membershipTypes[text || "free"],
    },
    {
      title: "Membership plan",
      width: 180,
      dataIndex: "membershipPlan",
      render: (text: string, row: any) => membershipPlans[text || "_null"],
    },
    {
      title: "Membership expire date",
      dataIndex: "membershipExpireDate",
      width: 220,
      key: "membershipExpireDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "email address",
      width: 180,
      dataIndex: "email",
    },
    {
      title: "Display name",
      width: 180,
      dataIndex: "displayName",
    },
    {
      title: "Legal Name",
      width: 120,
      dataIndex: "legalName",
    },
    {
      title: "user tag",
      dataIndex: "userTags",
      width: 180,
      className: "ellipsis-custom-cell",
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text?.filter(e => e).join(",") || "N/A",
    },
    {
      title: "Primary Discipline",
      dataIndex: "primaryDiscipline",
      className: "ellipsis-custom-cell",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string) => text || "N/A",
    },
    {
      title: "Other Disciplines",
      dataIndex: "disciplines",
      className: "ellipsis-custom-cell",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text?.join(",") || "N/A",
    },
    {
      title: "Job title",
      width: 120,
      dataIndex: "jobTitle",
    },
    {
      title: "ETHNIC",
      dataIndex: "etnBackground",
      width: 120,
      className: "ellipsis-custom-cell",
      ellipsis: {
        showTitle: true,
      },
      render: (text: string[]) => text?.join(",") || "N/A",
    },
    {
      title: "PRONOUNS",
      width: 110,
      dataIndex: "pronouns",
    },
    {
      title: "Years of experience",
      width: 180,
      dataIndex: "expirenceYears",
    },
    {
      title: "Payable Check",
      width: 150,
      dataIndex: "ach",
    },
    {
      title: "Address 1",
      dataIndex: "addresses",
      width: 130,
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: string[]) => (text && text[0]) || "N/A",
    },
    {
      title: "Address 2",
      dataIndex: "addresses",
      width: 130,
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: string[]) => (text && text[1]) || "N/A",
    },
    {
      title: "City",
      width: 80,
      dataIndex: "city",
    },
    {
      title: "State",
      width: 80,
      dataIndex: "state",
    },
    {
      title: "Zip code",
      width: 120,
      dataIndex: "zipCode",
    },
    {
      title: "Country",
      width: 100,
      dataIndex: "country",
    },
    {
      title: "Phone number",
      width: 150,
      dataIndex: "phone",
    },
    {
      title: "Date created",
      dataIndex: "createDate",
      width: 130,
      key: "createDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "SA contract",
      dataIndex: "signDate",
      width: 130,
      key: "signDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "last login",
      dataIndex: "loginDate",
      width: 130,
      key: "loginDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                {row.progress >= 4 && (
                  <Menu.Item onClick={() => handleAccessToUser(row.userId)}>
                    Access account
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={() =>
                    history.push(
                      admin_router_message_to.replace(":to", row.userId)
                    )
                  }
                >
                  Message
                </Menu.Item>
                <Menu.Item onClick={() => handleShowTagModal(row)}>
                  Edit user tag
                </Menu.Item>
                <Menu.Item onClick={() => handleViewNote(row)}>
                  View admin note
                </Menu.Item>
                <Menu.Item onClick={() => handleSuspend(row)}>
                  Suspend account
                </Menu.Item>
                <Menu.Item onClick={() => handleDelete(row)}>
                  Delete account
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });

  const [windowHeight] = useWindowSize();

  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [filterForm] = Form.useForm();
  const [filterModal, setFilterModal] = useState<any>(false);
  const handleViewFilter = () => {
    setFilterModal(true);
    filterForm.setFieldsValue(params);
  };
  const handleFilterFinish = (values: any) => {
    setSelectedRows([]);
    setTableData([]);
    setParams({
      ...params,
      ...values,
      page: 1,
      limit: 10,
    });
    setFilterModal(false);
  };

  const handleReset = () => {
    filterForm.resetFields();
    setText("");
    setParams({ page: 1, limit: 10, keyword: "" });
  };

  const getList = () => {
    let _data = params;
    artistList(_data)
      .then(({ items, meta, ...others }) => {
        setTableData(items);
        setSummary({
          ...others,
        });
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
        setSummary(null);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-artists-container admin-common-container">
      <h1>Artists</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>MEMBERSHIP STATUS</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.membershipType}
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  membershipType: e,
                });
              }}
              placeholder="select..."
            >
              {memberTypes.slice(0, -1).map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>user tag</span>
            <Select
              allowClear
              value={params?.userTag}
              placeholder="select..."
              mode="multiple"
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  userTag: e,
                  userTagCondition: "one_of",
                });
              }}
            >
              {tagList.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>Discipline</span>
            <Select
              allowClear
              mode="multiple"
              value={params?.discipline}
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  discipline: e,
                  disciplineCondition: "one_of",
                });
              }}
              placeholder="select... "
            >
              {options.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              {/*<Menu.Item onClick={handleShowUserModal}>Add new user</Menu.Item>*/}
              {/*<Menu.Item onClick={handleShowTagModal}>*/}
              {/*  Add new user tag*/}
              {/*</Menu.Item>*/}
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      <div className="data-chart-container flex">
        <span>
          <i>Number of artists</i>
          <i>{summary?.total || 0}</i>
        </span>
        <span>
          <i>Number of Sponsored Artists</i>
          <i>{summary?.sponsoredCount || 0}</i>
        </span>
        <span>
          <i>Donation raised</i>
          <i>
            $ {summary?.donationSum?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
          </i>
        </span>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Artists</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                })
              }
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 565 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title={tagModal === true ? "Add new user tag" : "Edit user tag"}
        className="common-modal admin-user-modal"
        visible={!!tagModal}
        onOk={() => tagForm.submit()}
        okText="save"
        cancelText="cancel"
        okButtonProps={{
          loading,
        }}
        onCancel={() => setTagModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={tagForm}
          onFinish={handleTagFinish}
          layout="vertical"
          name="form_tag"
        >
          <p className="text-12">
            {tagModal === true
              ? "You are adding new tag to the system. You can use this tag to filter users later on."
              : "You can use this form below to add or edit the user tags that are assigned to this user."}
          </p>
          {tagModal === true ? (
            <>
              <Form.Item
                label="User tag"
                name="name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "User tag",
                  },
                ]}
              >
                <Input maxLength={250} placeholder="User tag" />
              </Form.Item>
              <p className="tag-list">
                {tagList?.map((item) => (
                  <Tag key={item.name}>{item.name}</Tag>
                ))}
              </p>
            </>
          ) : (
            <>
              <Form.Item noStyle name="id">
                <Input hidden disabled />
              </Form.Item>
              <Form.Item
                label="User tag"
                name="tags"
                rules={[
                  {
                    // required: true,
                    type: "array",
                    message: "User tag",
                  },
                ]}
              >
                <Select
                  bordered={false}
                  placeholder="Select..."
                  mode="multiple"
                >
                  {tagList?.map((item) => (
                    <Select.Option key={item.name} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Admin note"
        className="common-modal admin-user-modal"
        visible={noteModal}
        onOk={() => noteForm.submit()}
        okText="save changes"
        cancelText="cancel"
        onCancel={() => setNoteModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={noteForm}
          onFinish={handleNoteFinish}
          layout="vertical"
          name="form_note"
        >
          <Form.Item noStyle name="userId">
            <Input hidden disabled />
          </Form.Item>
          <div className="flex">
            <Form.Item label="First name" name="firstName">
              <Input disabled maxLength={50} placeholder="First name" />
            </Form.Item>
            <Form.Item label="Last name" name="lastName">
              <Input disabled maxLength={50} placeholder="Last name" />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item label="User role" name="roles">
              <Select
                disabled
                allowClear
                bordered={false}
                placeholder="Select a role "
              >
                {roles.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="User tag" name="tags">
              <Input disabled maxLength={50} placeholder="User tag" />
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item label="email" name="email">
              <Input disabled maxLength={100} placeholder="Email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
              normalize={(e) => {
                return e.replace(/[^\d\-()]*/g, "");
              }}
            >
              <Input disabled maxLength={50} placeholder="Phone" />
            </Form.Item>
          </div>

          <Form.Item
            className="textarea-wrap"
            label="Note"
            name="note"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "note",
              },
            ]}
          >
            <Input.TextArea placeholder="Note..." />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-filter-modal admin-artist-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={filterForm}
          onFinish={handleFilterFinish}
          layout="vertical"
          name="form_filter"
        >
          <Form.Item label="Search admin note" name="keyword">
            <Input allowClear placeholder="Search..." />
          </Form.Item>
          <h4>Membership plan</h4>
          <Form.Item label="Membership plan" name="membershipPlan">
            <Select
              allowClear
              mode="multiple"
              placeholder="select..."
              bordered={false}
            >
              <Select.Option value="month">Monthly</Select.Option>
              <Select.Option value="year">Annual</Select.Option>
            </Select>
          </Form.Item>

          <Divider />
          <h4>Membership type</h4>
          <div className="flex">
            <Form.Item label="Condition" name="membershipCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="membership type" name="membershipType">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {memberTypes.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Discipline</h4>
          <div className="flex">
            <Form.Item label="Condition" name="disciplineCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Discipline" name="discipline">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {options.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <h4>user tag</h4>
          <div className="flex">
            <Form.Item label="Condition" name="userTagCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="user tag" name="userTag">
              <Select
                allowClear
                mode="multiple"
                placeholder="select..."
                bordered={false}
              >
                {tagList.map((item: any) => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />

          <h4>Date ranges</h4>
          <Form.Item label="membership expiry date" name="membershipDate">
            <DatePicker.RangePicker placeholder={["From", "To"]} />
          </Form.Item>
          <Form.Item label="account create" name="createDate">
            <DatePicker.RangePicker
              placeholder={["From", "To"]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
          <Form.Item label="SA Contract" name="signDate">
            <DatePicker.RangePicker
              placeholder={["From", "To"]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
          <Form.Item label="last login date" name="loginDate">
            <DatePicker.RangePicker placeholder={["From", "To"]} />
          </Form.Item>
          <Divider />
          <h4>Location</h4>
          <Form.Item label="City" name="city">
            <Input placeholder="City" maxLength={30} />
          </Form.Item>
          <Form.Item label="State" name="state" className="select-container">
            <Select
              allowClear
              mode="multiple"
              bordered={false}
              placeholder="State"
            >
              {Object.values(stateList)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex">
            <Form.Item label="Zipcode Condition" name="zipcodeCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Zipcode" name="zipcode">
              <Input placeholder="Zipcode" maxLength={30} />
            </Form.Item>
          </div>
          <Form.Item
            label="country"
            name="country"
            className="select-container"
            initialValue="United States"
          >
            <Select bordered={false} placeholder="country">
              <Select.Option value="United States">United States</Select.Option>
            </Select>
          </Form.Item>
          <Divider />
          <h4>Years of experience</h4>
          <div className="flex">
            {/*<Form.Item*/}
            {/*  label="Condition"*/}
            {/*  name="Condition"*/}
            {/*  className="select-container"*/}
            {/*  initialValue="Is one of"*/}
            {/*>*/}
            {/*  <Select placeholder="State">*/}
            {/*    <Select.Option value="Is one of">Is one of</Select.Option>*/}
            {/*    <Select.Option value="Is all of">Is all of</Select.Option>*/}
            {/*    <Select.Option value="Is none of">Is none of</Select.Option>*/}
            {/*  </Select>*/}
            {/*</Form.Item>*/}
            <Form.Item label="Years of experience" name="experienceYear">
              <Select bordered={false} placeholder="Select..." mode="multiple">
                <Select.Option value="0-5">0 to 5 years </Select.Option>
                <Select.Option value="5-10 ">5 to 10 years </Select.Option>
                <Select.Option value="10-50">More than 10 years</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <h4>Demographic</h4>
          <div className="flex">
            <Form.Item label="Condition" name="backgroundCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Demographic"
              name="background"
              className="select-container"
            >
              <Select
                mode="multiple"
                placeholder="Select demographic"
                bordered={false}
              >
                {ethnic.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {component}
      {deleteComponent}
    </div>
  );
};
